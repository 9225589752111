import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Card, Header, Icon, Message, Segment } from 'semantic-ui-react';

// Import actions
import { userHasPermission } from '../../api/firebase/account';

// Import Components
import { ContactStatus } from '@wonderschool/common-base-types';
import { SendInvitationConfirmationDialog } from '../../contacts';
import { inviteContact } from '../../contacts/contactsAPI';
import { useContacts } from '../../contacts/contactsHooks';
import { useUser } from '../../hooks/useUser';
import StaffForm from '../Forms/StaffForm';
import { showErrorToast, showSuccessToast } from '../Shared/showToast';
import withPermission from '../Shared/withPermission';
import withSlidingPanel from '../Shared/withSlidingPanel';
import StaffEditCard from './StaffEditCard';

const RestrictedStaffCreateCard = withPermission(StaffCreateCard, 'can_create_staff');

const SlidingStaffFormAdd = withSlidingPanel(StaffForm, {
  title: 'Add Staff',
});

const SlidingStaffFormEdit = withSlidingPanel(StaffForm, {
  title: 'Edit Staff',
});

export default function StaffList() {
  const { t } = useTranslation();

  const { staffContacts } = useContacts();
  const { isOrganizationAdmin } = useUser();

  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedStaffContact, setSelectedStaffContact] = useState(null);
  const [sendToStaffContact, setSendToStaffContact] = useState(null);

  const showInvitationSuccessToast = useCallback(
    ({ displayName, email }) => {
      const message = t('An invitation was sent to {{displayName}} at ({{email}})', { displayName, email });
      showSuccessToast(t('Staff Invitation Sent'), message);
    },
    [t]
  );

  const showInvitationErrorToast = useCallback(
    ({ displayName, email }, error) => {
      const message = t('Failed to send a staff invitation to {{displayName}} at ({{email}})', { displayName, email });
      showErrorToast(t('Staff Invitation Failed'), message, error);
    },
    [t]
  );

  const onClickSend = useCallback((contact) => {
    setSendToStaffContact(contact);
  }, []);

  const onSendInvitation = useCallback(
    async (contact) => {
      try {
        contact.status = ContactStatus.inviting;
        await inviteContact(contact);
        showInvitationSuccessToast(contact);
      } catch (error) {
        console.log(error);
        showInvitationErrorToast(contact, error);
      } finally {
        setSendToStaffContact(null);
      }
      return null;
    },
    [showInvitationErrorToast, showInvitationSuccessToast]
  );

  const onClickStaff = useCallback((staffContact) => {
    // can't edit an invitation, or if no permissions
    if (!userHasPermission('can_edit_staff')) return;
    setSelectedStaffContact(staffContact);
    setIsEditOpen(true);
  }, []);

  const renderStaffList = useCallback(() => {
    if (staffContacts?.length === 0 && !isOrganizationAdmin) {
      return (
        <Message warning data-testid="staff-warning-message">
          <Message.Content>{t('You have no staff to display.')}</Message.Content>
        </Message>
      );
    }
    return (
      <Card.Group stackable itemsPerRow={4}>
        <RestrictedStaffCreateCard onClick={() => setIsAddOpen(true)} data-testid="create-res-staff" />

        {!!staffContacts &&
          staffContacts.map((staffContact) => (
            <StaffEditCard
              key={staffContact.id}
              staffContact={staffContact}
              onClick={(_e) => onClickStaff(staffContact)}
              onClickSend={onClickSend}
              t={t}
            />
          ))}
      </Card.Group>
    );
  }, [onClickSend, onClickStaff, staffContacts, isOrganizationAdmin, t]);

  const renderPageTitle = useCallback(() => {
    if (staffContacts?.length) {
      return (
        <Segment basic clearing>
          <Header
            as="h1"
            floated="left"
            content={t('Staff ({{staffCount}})', {
              staffCount: staffContacts.length,
            })}
          ></Header>
        </Segment>
      );
    }

    return (
      <Segment basic clearing>
        <Header as="h1" floated="left" content={t('Staff')} />
      </Segment>
    );
  }, [staffContacts.length, t]);

  const renderSlidingForms = useCallback(() => {
    return (
      <>
        <SlidingStaffFormAdd isOpen={isAddOpen} onClose={() => setIsAddOpen(false)} />
        <SlidingStaffFormEdit
          isOpen={isEditOpen}
          onClose={() => {
            setSelectedStaffContact(null);
            setIsEditOpen(false);
          }}
          staffContact={selectedStaffContact}
        />
      </>
    );
  }, [isAddOpen, isEditOpen, selectedStaffContact]);

  return (
    <div>
      {renderPageTitle()}
      {renderStaffList()}
      {renderSlidingForms()}

      <SendInvitationConfirmationDialog
        onClose={() => setSendToStaffContact(null)}
        onYes={onSendInvitation}
        contact={sendToStaffContact}
      />
    </div>
  );
}

function StaffCreateCard({ onClick }) {
  const { t } = useTranslation();
  return (
    <Card className="add-new" onClick={onClick} data-testid="add-new-staff">
      <Card.Content>
        <div className={'center'}>
          <Card.Header as={'h4'}>
            <Icon name="plus" data-testid="add-new-staff-icon" />
          </Card.Header>
          <Card.Description data-testid="add-more-staff">{t('Add more staff')}</Card.Description>
        </div>
      </Card.Content>
    </Card>
  );
}
