import { Contact, ContactStatus } from '@wonderschool/common-base-types';
import { Tooltip } from '@wonderschool/common-base-ui';
import CN from 'classnames';
import { useMemo, useState } from 'react';
import Avatar from 'react-avatar';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Header, Icon, Label, Loader, Popup, Segment } from 'semantic-ui-react';
import { userHasPermission } from '../../../api/firebase/account';
import {
  ContactStatusLabel,
  SendInvitationConfirmationDialog,
  showInvitationErrorToast,
  showInvitationSuccessToast,
} from '../../../contacts';
import { inviteContact } from '../../../contacts/contactsAPI';
import { useContacts } from '../../../contacts/contactsHooks';
import { formatFullName, phoneNumberParse } from '../../../helpers/utils';
import { useOrganization } from '../../../hooks/useOrganizations';
import { useUser } from '../../../hooks/useUser';
import { WsCheckmark, WsClose, WsDelete, WsEdit } from '../../../icons';
import { RELATIONSHIP_TYPES } from '../../consts';
import { createContactDataForDelete, createResponsibleForBillingDataForDelete, saveStudent } from '../../studentsAPI';
import { useSelectedStudent } from '../../studentsHooks';
import { getOrCreateRelationshipNotes } from '../../studentsUtils';
import DeleteContactConfirmationDialog from './DeleteContactConfirmationDialog';
import FamilyEditContactModal from './FamilyEditContactModal';
import styles from './FamilyList.module.scss';

export default function FamilyList() {
  const { t } = useTranslation();

  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [sendToContact, setSendToContact] = useState(null);

  const { isParent } = useUser();
  const canRoleEditStudentContacts = userHasPermission('can_edit_student_contacts');
  const organization = useOrganization();
  const student = useSelectedStudent();
  const { familyContacts } = useContacts();

  const contacts = useMemo(() => {
    if (isParent) {
      return Object.keys(student.family || {}).map((contactId) => ({
        id: contactId,
        ...student.family[contactId],
      }));
    }

    const familyMembers = student?.familyMembers || [];

    return familyContacts.filter((contact) => familyMembers.includes(contact.id));
  }, [student?.familyMembers, familyContacts, isParent, student?.family]);

  const removeContactFromStudent = async (contactToRemove) => {
    const isResponsible = student.responsibleForBilling && student.responsibleForBilling.id === contactToRemove.id;
    const responsibleData = isResponsible ? createResponsibleForBillingDataForDelete() : {};

    const data = {
      id: student.id,
      ...createContactDataForDelete(contactToRemove),
      ...responsibleData,
    };

    return saveStudent(organization.id, data);
  };

  return (
    <>
      {contacts.length ? renderTable() : renderNoData()}

      <FamilyEditContactModal
        student={student}
        contact={selectedContact}
        isOpen={isEditContactModalOpen}
        onClose={onCloseEditContactModal}
        onReplaceExistingContact={removeContactFromStudent}
      />
      <DeleteContactConfirmationDialog
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => setIsDeleteConfirmationDialogOpen(false)}
        onYes={async () => {
          setIsDeleteConfirmationDialogOpen(false);
          return removeContactFromStudent(selectedContact);
        }}
      />
      <SendInvitationConfirmationDialog
        onClose={() => setSendToContact(null)}
        onYes={onSendInvitation}
        contact={sendToContact}
      />
    </>
  );

  function renderTable() {
    return (
      <div className="bootstrap-iso">
        <BootstrapTable
          bootstrap4
          classes="w-auto w-md-100"
          wrapperClasses="table-responsive"
          noDataIndication={t('common.noResultsToDisplay')}
          keyField="id"
          bordered={false}
          data={contacts}
          columns={getTableColumns()}
        />
      </div>
    );
  }

  function renderNoData() {
    return (
      <Segment basic clearing textAlign="center" className="no-shadow no-border top-border">
        <Header as={'h3'}>
          <Icon circular inverted name="info" color="grey" />
        </Header>
        <Header as={'h4'}>{t('familyList.noContactAdded')}</Header>
        <p className="text-muted">{t('familyList.instructions')}</p>
      </Segment>
    );
  }
  function onClickEdit(contact) {
    setSelectedContact(contact);
    setIsEditContactModalOpen(true);
  }
  function onCloseEditContactModal() {
    setSelectedContact(null);
    setIsEditContactModalOpen(false);
  }
  function onClickDelete(contact) {
    setSelectedContact(contact);
    setIsDeleteConfirmationDialogOpen(true);
  }
  function onClickSend(contact) {
    setSendToContact(contact);
  }
  async function onSendInvitation(contact) {
    try {
      contact.status = ContactStatus.inviting;
      await inviteContact(contact);
      showInvitationSuccessToast(contact);
    } catch (error) {
      console.log(error);
      showInvitationErrorToast(contact, error);
    } finally {
      setSendToContact(null);
    }
    return null;
  }

  function getTableColumns() {
    return [
      {
        dataField: 'id',
        text: 'id',
        hidden: true,
      },
      {
        text: '',
        dataField: 'avatar',
        formatter: (cell, row) => {
          return (
            <Avatar
              name={formatFullName(row, true)}
              round
              size={'48'}
              className="left floated"
              src={row?.photoURL || row?.picture}
            />
          );
        },
        headerStyle: () => {
          return { width: '60px' };
        },
      },
      {
        dataField: 'displayName',
        formatter: (cell, row, _rowIndex, _formatExtraData) => (
          <div>
            <div
              className={CN('textTruncate', 'family-member-name')}
              style={{ fontSize: '14px', fontWeight: 'bolder' }}
              data-testid="family-member-name"
            >
              {cell || formatFullName(row)}
            </div>
            <div>
              {/* Hide bogus emails because they confuse users. */}
              {row.email && !row.email.startsWith('moxit_') ? (
                <Tooltip content={row.email} action={'hover'}>
                  <div className={CN('textTruncate', 'family-member-email')} data-testid="family-member-email">
                    {row.email}
                  </div>
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          </div>
        ),
        text: t('common.name'),
        sort: true,
        headerAlign: 'left',
        style: { verticalAlign: 'middle' },
        headerStyle: () => {
          return { width: '300px' };
        },
      },
      {
        dataField: 'phone',
        text: t('common.phone'),
        headerAlign: 'left',
        style: { verticalAlign: 'middle' },
        formatter: (cell) => phoneNumberParse(cell),
        headerStyle: () => {
          return { width: '130px' };
        },
      },
      {
        dataField: 'relationshipNotes',
        text: t('common.relationship'),
        headerAlign: 'left',
        style: { verticalAlign: 'middle' },
        formatter: (cell, row) => getOrCreateRelationshipNotes(row, t),
        headerStyle: () => {
          return { width: '165px' };
        },
      },
      {
        dataField: 'allowLoginWithPin',
        text: t('common.pickup'),
        headerAlign: 'left',
        style: { verticalAlign: 'middle' },
        formatter: (cell) =>
          cell ? <WsCheckmark className={styles.checkmark} /> : <WsClose className={styles.close} />,
        headerStyle: () => {
          return { width: '60px' };
        },
      },
      {
        dataField: 'status',
        text: t('common.status'),
        headerAlign: 'left',
        style: { verticalAlign: 'middle' },
        formatter: (_cell, row) => <ContactStatusLabel contact={row} />,
        headerStyle: () => {
          return { width: '140px' };
        },
      },
      // parents, teachers, support staff cannot edit or delete family members
      ...(!canRoleEditStudentContacts
        ? []
        : [
            {
              text: t('familyList.invitation'),
              dataField: '',
              align: 'right',
              style: { verticalAlign: 'middle' },
              formatter: (_cell, row: Contact) => {
                const isAuthorizedToPickup =
                  row.relationship === RELATIONSHIP_TYPES.parent ||
                  (row.relationship === RELATIONSHIP_TYPES.contact && row.allowLoginWithPin);

                return (
                  <div className={styles.actionIconBtn}>
                    {row.status === ContactStatus.invited && (
                      <Popup
                        content={t('familyList.reinviteParent')}
                        position="right center"
                        offset={[4, 0]}
                        trigger={
                          <Button
                            icon="redo"
                            color="blue"
                            inverted
                            circular
                            size="tiny"
                            onClick={() => onClickSend(row)}
                            data-testid="re-invite-parent-btn"
                          />
                        }
                      />
                    )}
                    {row.status === ContactStatus.new && isAuthorizedToPickup && (!row.uid || row.uid === row.id) && (
                      <Popup
                        content={row.email ? t('familyList.inviteParentToJoin') : t('familyList.addEmailToInvite')}
                        position="right center"
                        offset={[4, 0]}
                        trigger={
                          <span>
                            <Button
                              icon="paper plane outline"
                              color={row.email ? 'blue' : 'grey'}
                              circular
                              size="tiny"
                              disabled={!row.email}
                              onClick={() => {
                                if (row.email) onClickSend(row);
                              }}
                              data-testid="send-invite-btn"
                            />
                          </span>
                        }
                      />
                    )}
                    {row.status === ContactStatus.inviting && (
                      <Popup
                        content={t('familyList.currentlyInvitingParent')}
                        position="right center"
                        offset={[4, 0]}
                        trigger={
                          <Label circular color="blue" size="large" data-testid="loader">
                            <Loader size="tiny" inverted active inline />
                          </Label>
                        }
                      />
                    )}
                    <span>
                      <a onClick={() => onClickEdit(row)} data-testid="edit-btn" className={styles.actionIconBtn}>
                        <WsEdit />
                      </a>
                      <a onClick={() => onClickDelete(row)} data-testid="delete-btn" className={styles.actionIconBtn}>
                        <WsDelete />
                      </a>
                    </span>
                  </div>
                );
              },
              headerStyle: () => {
                return { width: '150px' };
              },
            },
          ]),
    ];
  }
}
