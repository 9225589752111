// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import store from '../redux/store';

import { EnrollmentStatusEnum, HourEnum } from '../enrollments/enums';
import { ScheduledDayType, UnenrollmentType } from '../enrollments/types';
import { getDisplayNameOrFormatFullName } from '../helpers/utils';
import { InterestedFamilyType } from '../integrations/marketplace/types';

import { StudentType } from '@wonderschool/common-base-types';
import { CONTACT_NOTES, PARENT_NOTES, RELATIONSHIP_TYPES, STUDENT_FAMILY_CONTACT_FIELDS } from './consts';
import { StudentStatusEnum } from './types';

const randomPictures = [
  'https://www.moxit.com/assets/images/placeholders/profile-01.png',
  'https://www.moxit.com/assets/images/placeholders/profile-02.png',
  'https://www.moxit.com/assets/images/placeholders/profile-03.png',
  'https://www.moxit.com/assets/images/placeholders/profile-04.png',
  'https://www.moxit.com/assets/images/placeholders/profile-05.png',
  'https://www.moxit.com/assets/images/placeholders/profile-06.png',
  'https://www.moxit.com/assets/images/placeholders/profile-07.png',
  'https://www.moxit.com/assets/images/placeholders/profile-08.png',
  'https://www.moxit.com/assets/images/placeholders/profile-09.png',
  'https://www.moxit.com/assets/images/placeholders/profile-10.png',
  'https://www.moxit.com/assets/images/placeholders/profile-11.png',
];

export const getRandomStudentPicture = (): string => {
  return randomPictures[_.random(0, randomPictures.length - 1)] || randomPictures[0];
};

export const getPrimaryLocation = (locations: any[]) => {
  if (!locations?.length) return null;
  return locations.find((location) => location.primary) || locations[0];
};

const findLocationIdFromRoom = (student: any) => {
  const { students, rooms } = store.getState();
  const studentState = students.list?.find((s: any) => s.id === student.id);

  if (!studentState || !studentState.rooms || studentState.rooms.length <= 0) return null;

  const roomId = studentState.rooms[0];
  const roomState = rooms.list.find((r: any) => r.id === roomId);

  if (!roomState) return null;

  return roomState.location;
};

export const findPrimaryLocationId = () => {
  const { locations } = store.getState();
  return getPrimaryLocation(locations?.list || [])?.id ?? null;
};

export const findLocationIdForStudent = (student: any) => findLocationIdFromRoom(student) || findPrimaryLocationId();

// Take the original relationship types and munge them into a newer format
// Does NOT mutate the original object
export const transformRelationship = (data: any) => {
  const { relationship = null, relationshipNotes = null, ...rest } = data ?? {};

  // eliminate the 'guardian' relationship
  const newRelationship = relationship === RELATIONSHIP_TYPES.guardian ? RELATIONSHIP_TYPES.contact : relationship;

  return { ...rest, relationship: newRelationship, relationshipNotes };
};

export const getOrCreateRelationshipNotes = (data: any, t: (a: string) => string) => {
  const { relationship = null, relationshipNotes = null } = data ?? {};

  if (relationshipNotes) return relationshipNotes;

  if (relationship === RELATIONSHIP_TYPES.parent) {
    return t(PARENT_NOTES);
  } else {
    return t(CONTACT_NOTES);
  }
};

export const createArrayOfFamilyContacts = (student: any) => {
  if (!student?.family || !Object.keys(student.family).length || !student.familyMembers?.length) return [];

  const contacts = Object.keys(student.family).map((id) => {
    return { id, ...student.family[id] };
  });
  return contacts;
};

/**
 * Returns true if student matches searchText. Tries to match with displayName as
 * well as names in families.
 */
export const doesQueryMatchStudent = (searchText: string) => (student: any) => {
  const { displayName, family, allergies } = student;

  const searchTextLower = searchText.toLowerCase();

  if (displayName.toLowerCase().includes(searchTextLower)) {
    return true;
  }

  if (allergies?.toLowerCase().includes(searchTextLower)) {
    return true;
  }

  if (family) {
    return Object.keys(family).some((key) => {
      const name = getDisplayNameOrFormatFullName(family[key], true).toLowerCase();
      return name.includes(searchTextLower);
    });
  }
  return false;
};

/**
 * Returns the subarray of students that match searchText. Tries to match with displayName as
 * well as names in families.
 */
export const searchStudents = (searchText: string, students: any[]) =>
  students.filter(doesQueryMatchStudent(searchText));

export const computeEnforceAutomaticPayment = (student: any, organization: any) => {
  const studentModifiedDate = student.automaticPayment?.modifiedDate ?? 0;
  const organizationModifiedDate = organization.automaticPayment?.modifiedDate ?? 0;

  return studentModifiedDate > organizationModifiedDate
    ? !!student.automaticPayment?.enforce
    : !!organization.automaticPayment?.enforce;
};

export const createStudentFamilyFromContact = (contact: any) => {
  return STUDENT_FAMILY_CONTACT_FIELDS.reduce((acc: any, key) => {
    if (contact[key]) {
      acc[key] = contact[key];
    }
    return acc;
  }, {});
};

export const getEnrollmentDate = (student: any): Date => {
  return student?.enrollment?.startDate ?? student.enrollmentDate;
};

export const getUnenrollmentData = (student: any): UnenrollmentType | null => {
  if (!student) return null;

  const unenrolled = student.enrollment?.unenrolled;

  return {
    reason: unenrolled?.reason ?? student.unenrollmentReason,
    initiatedBy: unenrolled?.initiatedBy ?? student.unenrollmentInitiatedBy,
    date: unenrolled?.date ?? student.unenrollmentDate,
    notes: unenrolled?.notes ?? student.unenrollmentNotes,
  } as UnenrollmentType;
};

// These are wrappers around the legacy student.enrollmentXXX and student.enrollment.XXX fields
// First check the enrollment map, then the enrollmentXXX fields
export const resolveLegacyStatus = (student: any) => {
  const todaysDate = new Date();
  if (student?.enrollmentStatus) {
    if (todaysDate.valueOf() < student?.enrollmentDate) {
      return EnrollmentStatusEnum.PENDING;
    } else {
      return EnrollmentStatusEnum.ENROLLED;
    }
  } else {
    return EnrollmentStatusEnum.UNENROLLED;
  }
};
export const getEnrollmentStatus = (student: any): EnrollmentStatusEnum => {
  return student?.enrollment?.status ?? resolveLegacyStatus(student);
};

export const isStudentEnrolled = (student: any): boolean => {
  return getEnrollmentStatus(student) === EnrollmentStatusEnum.ENROLLED;
};
export const isStudentUnenrolled = (student: any): boolean => {
  return getEnrollmentStatus(student) === EnrollmentStatusEnum.UNENROLLED;
};
export const isStudentPending = (student: any): boolean => {
  return getEnrollmentStatus(student) === EnrollmentStatusEnum.PENDING;
};

// sets the enrollment status and returns a new student object
export const setEnrollmentStatus = (student: any, status: EnrollmentStatusEnum): any => {
  if (!student || !status) throw new Error('setEnrollmentStatus: missing required parameters');

  const legacyStatus: boolean = status === EnrollmentStatusEnum.ENROLLED;
  const newEnrollment = { ...(student?.enrollment ?? {}), status: status };
  const newStudent = { ...student, enrollmentStatus: legacyStatus, enrollment: newEnrollment };
  return newStudent;
};

// Student archive/unarchive
export const isStudentArchived = (student: any): boolean => {
  return student?.status === StudentStatusEnum.ARCHIVED;
};

export const archiveStudent = (student: any): any => {
  if (!student) throw new Error('archiveStudent: missing required parameters');
  if (!isStudentUnenrolled(student)) throw new Error('archiveStudent: student must be unenrolled');
  return { ...student, status: StudentStatusEnum.ARCHIVED };
};

export const unarchiveStudent = (student: any): any => {
  if (!student) throw new Error('unarchiveStudent: missing required parameters');
  if (!isStudentArchived(student)) throw new Error('unarchiveStudent: student must be archived');
  return { ...student, status: StudentStatusEnum.ACTIVE };
};

export const createScheduledDaysFromInterestedFamily = (interestedFamily: InterestedFamilyType): ScheduledDayType[] => {
  const { desiredSchedule = [], mostRecentApplicationSchedule = [] } = interestedFamily.student ?? {};

  const mpSchedule = desiredSchedule?.length ? desiredSchedule : mostRecentApplicationSchedule;
  return (mpSchedule ?? []).map((day) => {
    return {
      day: day.day,
      startTime: createHourEnumFromString(day.startsAt),
      endTime: createHourEnumFromString(day.endsAt),
    } as ScheduledDayType;
  });
};

export const createHourEnumFromString = (timestamp: string): HourEnum | null => {
  const [hour, minute] = timestamp.split(':');
  const testTime = `${hour}:${minute}`;

  let foundTime: HourEnum | null = null;
  Object.values(HourEnum).forEach((hour) => {
    if (hour <= testTime) {
      foundTime = hour;
    }
  });
  return foundTime;
};

export function getRelatedStudentsByContact(contactId: string, students: StudentType[]): any {
  return students.filter((student) => student.familyMembers?.includes(contactId));
}
