import {
  EditFormSection,
  InputData,
  Option,
  showToast,
  ToastTypeEnum,
  usStateOptions,
} from '@wonderschool/common-base-ui';
import { useStudentUpdateMutation } from '@wonderschool/common-food-program-data-access';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showErrorToast } from '../../../Components/Shared/showToast';
import { studentUpdated } from '../../studentsRedux';
import { useDefaultStudentValues } from './hooks/useDefaultStudentValues';
import { StudentPersonalInformationProps } from './studentForm.type';
import { transformStudentFormTypeForUpdateMutation } from './utils/transformStudentFormTypeForUpdateMutation';

type StudentAddressFormType = {
  line1?: string | null;
  line2?: string | null;
  zipOrPostalCode?: string | null;
  stateOrProvince?: Option;
  city?: string | null;
};

type StudentAddressApiType = Omit<StudentAddressFormType, 'stateOrProvince'> & { stateOrProvince?: string | null };

interface StudentAddressFormProps {
  student: StudentPersonalInformationProps['student'];
  address?: StudentAddressApiType | null;
}

export function StudentAddressForm({ student, address }: StudentAddressFormProps) {
  const { t } = useTranslation();

  const [updateStudentMutation] = useStudentUpdateMutation();
  const dispatch = useDispatch();

  const { memoizedValues } = useDefaultStudentValues(student);

  const memoizedInitialValues = useMemo(() => {
    const transformedState = usStateOptions.find(
      (usStateOption) => usStateOption.value.toLowerCase() === address?.stateOrProvince?.toLowerCase()
    );

    return {
      line1: address?.line1 || undefined,
      line2: address?.line2 || undefined,
      city: address?.city || undefined,
      stateOrProvince: transformedState,
      zipOrPostalCode: address?.zipOrPostalCode || undefined,
    };
  }, [address]);

  const inputs: InputData<StudentAddressFormType>[] = [
    {
      id: 'line1',
      label: t('Address 1'),
      inputType: 'text' as const,
      required: true,
    },
    {
      id: 'line2',
      label: t('Address 2'),
      inputType: 'text' as const,
      required: true,
    },
    {
      id: 'city',
      label: t('City'),
      inputType: 'text' as const,
      required: true,
    },
    {
      id: 'stateOrProvince',
      label: t('common.state'),
      inputType: 'us-state-select' as const,
      defaultValue: usStateOptions.find((stateOption) => stateOption.value === memoizedInitialValues.stateOrProvince),
      required: true,
    },
    {
      id: 'zipOrPostalCode',
      label: t('Zip Code'),
      inputType: 'text' as const,
      required: true,
      validationPattern: /^\d{5}(-\d{4})?$/,
    },
  ];

  async function onSubmit(data: StudentAddressFormType) {
    const { line1, line2, city, stateOrProvince, zipOrPostalCode } = data;

    const address = {
      line1,
      line2,
      city,
      stateOrProvince: stateOrProvince?.value,
      zipOrPostalCode,
    };

    const studentInput = transformStudentFormTypeForUpdateMutation(memoizedValues, student.avatarUrl);

    await updateStudentMutation({
      variables: {
        input: {
          studentIdentifier: { id: student.id, firebaseId: student.firebaseId },
          studentInput,
          address,
        },
      },
      onCompleted: () => {
        showToast(ToastTypeEnum.Success, 'Student updated successfully');
        dispatch(studentUpdated());
      },
      onError: (error) => {
        console.error('updateStudentMutation error', error);
        showErrorToast(t('Unable to save student data'));
      },
    });
  }

  return <EditFormSection onSubmit={onSubmit} inputs={inputs} values={memoizedInitialValues} header={t('Address')} />;
}
