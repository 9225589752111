// User
export const USER_SIGNED_IN = 'USER_SIGNED_IN';
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const USER_SIGNED_UP = 'USER_SIGNED_UP';
export const USER_SAVE_SIGNUP_DATA = 'USER_SAVE_SIGNUP_DATA';
export const USER_PROFILE_FETCHED = 'USER_PROFILE_FETCHED';
export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';
export const USER_EMAIL_RESET_LINK_SENT = 'USER_EMAIL_RESET_LINK_SENT';
export const USER_PROFILE_LISTENER_STARTED = 'USER_PROFILE_LISTENER_STARTED';
export const USER_PIN_UPDATED = 'USER_PIN_UPDATED';

// Permissions
export const PERMISSIONS_LISTENER_STARTED = 'PERMISSIONS_LISTENER_STARTED';
export const PERMISSION_CHANGED = 'PERMISSION_CHANGED';

// Roles
export const ROLES_LISTENER_STARTED = 'ROLES_LISTENER_STARTED';
export const ROLES_CHANGED = 'ROLES_CHANGED';

// Organization
export const ORGANIZATION_SELECTED = 'ORGANIZATION_SELECTED';
export const ORGANIZATION_MISSING = 'ORGANIZATION_MISSING';
export const ORGANIZATION_CREATED = 'ORGANIZATION_CREATED';
export const ORGANIZATION_UPDATED = 'ORGANIZATION_UPDATED';
export const ORGANIZATION_FETCHED = 'ORGANIZATION_FETCHED';
export const ORGANIZATION_PROVISIONING_STARTED = 'ORGANIZATION_PROVISIONING_STARTED';
export const ORGANIZATION_PROVISIONING_COMPLETE = 'ORGANIZATION_PROVISIONING_COMPLETE';
export const ORGANIZATION_LISTENER_STARTED = 'ORGANIZATION_LISTENER_STARTED';
export const MOXIT_ORGANIZATIONS_FETCHED = 'MOXIT_ORGANIZATIONS_FETCHED';
export const ORGANIZATION_SWITCHING = 'ORGANIZATION_SWITCHING';
export const ORGANIZATION_SWITCHED = 'ORGANIZATION_SWITCHED';
export const ORGANIZATION_CONTACTS_FETCHED = 'ORGANIZATION_CONTACTS_FETCHED';

// Interested Families from marketplace
export const INTERESTED_FAMILIES_FETCHED = 'INTERESTED_FAMILIES_FETCHED';

// Locations
export const LOCATIONS_FETCHED = 'LOCATIONS_FETCHED';
export const LOCATION_ADDED = 'LOCATION_ADDED';
export const LOCATION_UPDATED = 'LOCATION_UPDATED';
export const LOCATION_SELECTED = 'LOCATION_SELECTED';
export const LOCATION_SELECTION_CLEARED = 'LOCATION_SELECTION_CLEARED';
export const LOCATIONS_LISTENER_STARTED = 'LOCATIONS_LISTENER_STARTED';

// Rooms
export const ROOMS_FETCHED = 'ROOMS_FETCHED';
export const ROOM_ADDED = 'ROOM_ADDED';
export const ROOM_UPDATED = 'ROOM_UPDATED';
export const ROOM_SELECTED = 'ROOM_SELECTED';
export const ROOM_SELECTION_CLEARED = 'ROOM_SELECTION_CLEARED';
export const ROOMS_LISTENER_STARTED = 'ROOMS_LISTENER_STARTED';

// Staff
export const STAFF_FETCHED = 'STAFF_FETCHED';
export const STAFF_ADDED = 'STAFF_ADDED';
export const STAFF_INVITED = 'STAFF_INVITED';
export const STAFF_UPDATED = 'STAFF_UPDATED';
export const STAFF_SELECTED = 'STAFF_SELECTED';
export const STAFF_SELECTION_CLEARED = 'STAFF_SELECTION_CLEARED';
export const STAFF_LISTENER_STARTED = 'STAFF_LISTENER_STARTED';

// Students
export const STUDENTS_FETCHED = 'STUDENTS_FETCHED';
export const STUDENTS_LISTENER_STARTED = 'STUDENTS_LISTENER_STARTED';
export const STUDENT_ADDED = 'STUDENT_ADDED';
export const STUDENT_ADD_CLEARED = 'STUDENT_ADD_CLEARED';
export const STUDENT_UPDATED = 'STUDENT_UPDATED';
export const STUDENT_SELECTED = 'STUDENT_SELECTED';
export const STUDENT_SELECTION_CLEARED = 'STUDENT_SELECTION_CLEARED';
export const STUDENT_FAMILY_MEMBER_ADDED = 'STUDENT_FAMILY_MEMBER_ADDED';

// Layout
export const SLIDER_TOGGLED = 'SLIDER_TOGGLED';
export const SIDEBAR_TOGGLED = 'SIDEBAR_TOGGLED';
export const TIME_SECTION_TOGGLED = 'TIME_SECTION_TOGGLED';
export const SPACE_SECTION_TOGGLED = 'SPACE_SECTION_TOGGLED';

// Devices
export const DEVICES_FETCHED = 'DEVICES_FETCHED';
export const DEVICE_REFRESHED = 'DEVICE_REFRESHED';
export const DEVICE_SET = 'DEVICE_SET';
export const DEVICE_ADDED = 'DEVICE_ADDED';
export const DEVICE_ADDED_TO_LIST = 'DEVICE_ADDED_TO_LIST';
export const DEVICE_REMOVE_FROM_LIST = 'DEVICE_REMOVE_FROM_LIST';
export const DEVICE_UPDATED = 'DEVICE_UPDATED';
export const DEVICE_DELETED = 'DEVICE_DELETED';
export const DEVICE_SELECTED = 'DEVICE_SELECTED';
export const DEVICE_SELECTION_CLEARED = 'DEVICE_SELECTION_CLEARED';

// Billing
export const AJAX_CALL_BEGIN = 'AJAX_CALL_BEGIN';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const AJAX_CALL_SUCCESS = 'AJAX_CALL_SUCCESS';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';

export const LOG_IN = 'LOG_IN';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';

export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';

export const PAYMENT = {
  UPDATE_DETAILS: 'UPDATE_DETAILS',
  UPDATE_SELECTED_EMPLOYEES: 'UPDATE_SELECTED_EMPLOYEES',
  REMOVE_SELECTED_EMPLOYEE: 'REMOVE_SELECTED_EMPLOYEE',
  CONFIRM: 'CONFIRM',
  RESET_DATA: 'RESET_DATA',
};

export const LOAD_EMPLOYEES = 'LOAD_EMPLOYEES';
export const LOAD_EMPLOYEES_SUCCESS = 'LOAD_EMPLOYEES_SUCCESS';

export const INVOICES = {
  CREATE: 'CREATE',
  CREATE_SUCCESS: 'CREATE_SUCCESS',
  LOAD: 'LOAD',
  LOAD_SUCCESS: 'LOAD_SUCCESS',
};

export const INVOICES_LISTENER_STARTED = 'INVOICES_LISTENER_STARTED';
export const INVOICES_FETCHED = 'INVOICES_FETCHED';
export const INVOICE_PLANS_FETCHED = 'INVOICE_PLANS_FETCHED';
export const INVOICE_REFRESHED = 'INVOICE_REFRESHED';
export const INVOICE_SET = 'INVOICE_SET';
export const INVOICE_ADDED = 'INVOICE_ADDED';
export const INVOICE_ADDED_TO_LIST = 'INVOICE_ADDED_TO_LIST';
export const INVOICE_REMOVE_FROM_LIST = 'INVOICE_REMOVE_FROM_LIST';
export const INVOICE_UPDATED = 'INVOICE_UPDATED';
export const INVOICE_DELETED = 'INVOICE_DELETED';
export const INVOICE_SELECTED = 'INVOICE_SELECTED';
export const INVOICE_SELECTION_CLEARED = 'INVOICE_SELECTION_CLEARED';

export const CHARGES_FETCHED = 'CHARGES_FETCHED';
export const CHARGE_SAVED = 'CHARGE_SAVED';
export const CHARGE_SAVE = 'CHARGE_SAVE';

export const INVOICE_NEW = 'INVOICE_NEW';
export const INVOICE_EDIT = 'INVOICE_EDIT';

export const INVOICE_EDIT_SELECTION_CLEARED = 'INVOICE_EDIT_SELECTION_CLEARED';
export const INVOICE_EDIT_SELECTED = 'INVOICE_EDIT_SELECTED';
export const INVOICE_EDIT_DETAILS = 'INVOICE_UPDATE_DETAILS';
export const INVOICE_EDIT_ITEMS = 'INVOICE_UPDATE_ITEMS';
export const INVOICE_EDIT_STUDENTS = 'INVOICE_UPDATE_STUDENTS';
// Invitations
export const STUDENT_INVITATIONS_FETCHED = 'STUDENT_INVITATIONS_FETCHED';
export const ALL_STUDENT_INVITATIONS_FETCHED = 'ALL_STUDENT_INVITATIONS_FETCHED';
export const STAFF_INVITATIONS_FETCHED = 'STAFF_INVITATIONS_FETCHED';
export const INVITED_PARENTS_BY_IDS_FETCHED = 'INVITED_PARENTS_BY_IDS_FETCHED';

// Stats
export const STATS_LISTENER_STARTED = 'STATS_LISTENER_STARTED';
export const STATS_FETCHED = 'STATS_FETCHED';

// Onboarding
export const ONBOARDING_BANNER_COLLAPSED = 'ONBOARDING_BANNER_COLLAPSED';
