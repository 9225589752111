import { firestore } from '../api/firebase/firebase';
import { getDocumentsAsArray, saveDocument, startCollectionListener } from '../api/firebase/firestore';

import { ContactStatus, ContactType } from '@wonderschool/common-base-types';
import { formatFullName, getDisplayNameOrFormatFullName } from '../helpers/utils';
import { INVITATION_TYPES } from './enums';

const paths = {
  contact: (organizationId, contactId) => `organizations/${organizationId}/contacts/${contactId}`,
  contacts: (organizationId) => `organizations/${organizationId}/contacts`,
};

export const contactsOnListen = ({ organizationId, conditions }, next, error, complete) => {
  return startCollectionListener(
    {
      path: paths.contacts(organizationId),
      conditions,
      orderBy: [{ field: 'firstName', direction: 'asc' }],
    },
    next,
    error,
    complete
  );
};

export async function createContactForStudent(organization, location, student, contactData) {
  const contact = {
    ...contactData,
    displayName: formatFullName(contactData),
    defaultOrganization: organization.id,
    organizations: [organization.id],
    defaultLocation: location?.id || '',
    locations: location?.id ? [location.id] : [],
    student: {
      id: student.id ?? '',
      firstName: student.firstName ?? '',
      lastName: student.lastName ?? '',
      displayName: getDisplayNameOrFormatFullName(student),
      organization: student.organization ?? '',
    },
    studentIds: student?.id ? [student.id] : [],
    invitationType: contactData.invitationType ?? INVITATION_TYPES.parent,
  };
  const contactDocRef = await saveContact(organization.id, contact);
  contact.id = contactDocRef.id;
  return { contact, contactDocRef };
}

export async function updateContactForStudent(organization, location, student, contactData) {
  const contact = {
    ...contactData,
    displayName: formatFullName(contactData),
  };
  const contactDocRef = await saveContact(organization.id, contact);
  return { contact, contactDocRef };
}

// Return the docref, since we need the id
export async function saveContact(organizationId, contact, firestoreOptions = { merge: true }) {
  if (!organizationId) throw new Error('Organization Id is required');
  if (!contact) throw new Error('Contact is required');
  const { userId, key, combinedFamily, ...rest } = contact;

  const data = {
    ...rest,
    ...(rest?.email ? { email: rest.email.toLowerCase() } : {}),
    defaultOrganization: organizationId,
    organizations: firestore.FieldValue.arrayUnion(organizationId),
  };
  return saveDocument({
    collectionPath: paths.contacts(organizationId),
    data,
    firestoreOptions,
  });
}

export const inviteContact = async (contact) => {
  const data = {
    id: contact.id,
    status: ContactStatus.inviting,
    invitationType:
      contact.invitationType ??
      (contact.type === ContactType.family ? INVITATION_TYPES.parent : INVITATION_TYPES.staff),
    invitationNotes: contact.invitationNotes ?? null,
  };
  contact.status = ContactStatus.inviting;
  return saveContact(contact.defaultOrganization, data);
};

export const uninviteContact = async (contact) => {
  const data = {
    id: contact.id,
    status: ContactStatus.new,
    invitationId: firestore.FieldValue.delete(),
  };
  contact.status = ContactStatus.new;
  return saveContact(contact.defaultOrganization, data);
};

export const deleteContact = async (contact) => {
  const data = {
    id: contact.id,
    status: ContactStatus.deleted,
    email: contact.email.replace(/(@)/, '+deleted@'),
    loginPin: '',
    allowLoginWithPin: false,
  };
  return saveContact(contact.defaultOrganization, data);
};

export const fetchContacts = async (organizationId, conditions = []) => {
  return getDocumentsAsArray({
    path: paths.contacts(organizationId),
    conditions,
    orderBy: [{ field: 'firstName', direction: 'asc' }],
  });
};
