import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { OrganizationType } from '@wonderschool/common-base-types';
import { useFilesAccess } from '@wonderschool/file-service-client';

import { ArrowLeftIcon, ArrowRightIcon, Button } from '@wonderschool/common-base-ui';

import { useOrganization, useOrganizationContactsListener } from '../../../hooks/useOrganizations';
import { logError } from '../../../rollbar';

import { useContacts } from '../../../contacts/contactsHooks';
import { RouteNameEnum, useRoutes } from '../../../navigation';
import { useStudent } from '../../../students/studentsHooks';
import { getScheduledDaysAbbreviated } from '../../enrollmentsUtils';

import LoadingComponent from '../../../Components/Shared/LoadingComponent';
import PageHeader from '../../../Components/Shared/PageHeader';

import { saveStudentAndContacts } from '../../enrollmentsAPI';
import { useDocuments, useScheduledDays } from '../../enrollmentsHooks';
import { createInvitationRecipientsFromFamily } from '../../enrollmentsUtils';
import { DocumentType, InvitationRecipientType, OnSaveDataType, OnSaveParamsType } from '../../types';
import { DocumentsForm, InvitationRecipientsForm, ScheduleForm } from '../forms';

type EnrollmentPageProps = {
  onClick?: () => void;
};

const EnrollmentPage: React.FC<EnrollmentPageProps> = () => {
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useState(false);
  const [isWritingData, setIsWritingData] = useState(false);
  const [dataToSave, setDataToSave] = useState<OnSaveDataType>({});
  const [errors, setErrors] = useState<Record<string, string>>({});

  const params: { studentId: string } = useParams();
  const student = useStudent(params.studentId);
  const { familyContacts, contacts: _contacts } = useContacts();

  const { goBack, gotoRouteByName } = useRoutes();

  const organization: OrganizationType = useOrganization();
  useOrganizationContactsListener(organization?.id);

  const documents = useDocuments(student);
  const scheduledDays = useScheduledDays(student);

  const invitationRecipients = useMemo<InvitationRecipientType[]>(() => {
    return student ? createInvitationRecipientsFromFamily(student) : [];
  }, [student]);

  const {
    shareFiles,
    data: _shareData,
    isLoading: _isSharing,
    error: _shareError,
    isError: isError,
    isSuccess: isSuccess,
  } = useFilesAccess();

  const shouldSaveToFirestore = useCallback(() => {
    return isSaving && Object.keys(dataToSave).length >= 3 && Object.keys(errors).length === 0 && !isWritingData;
  }, [isSaving, dataToSave, errors, isWritingData]);

  // If the user entered an email address, add it to the contact and save
  const contactsToSaveToFirestore = useMemo(() => {
    if (!familyContacts || !dataToSave.invitationRecipients) return [];

    const recipients = (dataToSave.invitationRecipients ?? []) as InvitationRecipientType[];
    const changedContacts: any[] = [];

    for (const contact of familyContacts) {
      const recipient = recipients.find((r) => r.contactId === contact.id && r.email !== contact.email);

      if (recipient) {
        changedContacts.push({ id: contact.id, email: recipient.email });
      }
    }
    return changedContacts;
  }, [dataToSave.invitationRecipients, familyContacts]);

  const studentToSaveToFirestore = useMemo(() => {
    const data: any = {
      id: student?.id ?? '',
      enrollment: {
        invitationRecipients: dataToSave.invitationRecipients ?? [],
        documents: dataToSave.documents ?? [],
        tuitionAndFees: dataToSave.tuitionAndFees ?? {},
        scheduledDays: dataToSave.scheduledDays ?? [],
      },
    };
    // This is legacy and should be deprecated
    data.schedule = getScheduledDaysAbbreviated(data);
    return data;
  }, [dataToSave, student?.id]);

  const shareDocuments = useCallback(() => {
    const documents = dataToSave.documents as DocumentType[] | undefined;
    const documentIds = documents?.map((doc: DocumentType) => doc.id) ?? [];
    shareFiles({
      files: documentIds,
      students: [studentToSaveToFirestore.id],
    });
  }, [dataToSave.documents, shareFiles, studentToSaveToFirestore.id]);

  const onSave = useCallback(
    ({ data, errors: errorsParam }: OnSaveParamsType, fieldName: string) => {
      if (!isSaving) return false;
      if (errorsParam && Object.keys(errorsParam).length > 0) {
        setIsSaving(false);
        setErrors((prev) => ({ ...prev, ...errorsParam }));
        setDataToSave({});
      } else {
        setDataToSave((prev) => ({ ...prev, [fieldName]: data }));
      }
    },
    [isSaving]
  );

  useEffect(() => {
    const saveLocal = async () => {
      try {
        setIsWritingData(true);
        await saveStudentAndContacts({
          organization,
          student: studentToSaveToFirestore,
          contacts: contactsToSaveToFirestore,
        });

        const documents = dataToSave.documents as DocumentType[] | undefined;
        if (documents && documents.length > 0) {
          shareDocuments();
        }
      } catch (error) {
        setIsWritingData(false);
        setIsSaving(false);
        setErrors({ save: `Error while saving student or sharing documents: ${error}` });
        logError('Error while saving student or sharing documents', error);
      }
    };
    if (shouldSaveToFirestore()) {
      saveLocal();
    }
  }, [
    contactsToSaveToFirestore,
    dataToSave.documents,
    organization,
    shareDocuments,
    shouldSaveToFirestore,
    studentToSaveToFirestore,
  ]);

  // Redirect to summary page when document sharing has completed
  useEffect(() => {
    const documents = dataToSave.documents as DocumentType[] | undefined;
    if (isSuccess || isError || (isSaving && documents?.length == 0)) {
      setIsWritingData(false);
      if (organization.stripe?.accountId) {
        gotoRouteByName(RouteNameEnum.STUDENT_ENROLLMENT_INVOICE, [
          { name: 'studentId', value: studentToSaveToFirestore.id },
        ]);
      } else {
        gotoRouteByName(RouteNameEnum.STUDENT_ENROLLMENT_SUMMARY, [
          { name: 'studentId', value: studentToSaveToFirestore.id },
        ]);
      }
    }
  }, [
    isSaving,
    dataToSave.documents,
    gotoRouteByName,
    isError,
    isSuccess,
    studentToSaveToFirestore.id,
    organization.stripe?.accountId,
  ]);

  if (!student) return <LoadingComponent />;

  return (
    <div className="p-6">
      <form className={`space-y-8 ${isSaving ? 'opacity-50' : ''}`}>
        <PageHeader pageName={'Enrollment'} classes="enrollments-page" />
        <h1 className="text-3xl font-bold">{t('enrollments.pageTitle', { name: student.displayName ?? 'N/A' })}</h1>
        <div className="overflow-hidden bg-white p-6 shadow-md sm:rounded-lg">
          <InvitationRecipientsForm
            invitationRecipients={invitationRecipients}
            isSaving={isSaving && !dataToSave.invitationRecipients}
            onSave={(d) => onSave(d, 'invitationRecipients')}
          />
        </div>
        <div className="overflow-hidden bg-white p-6 shadow-md sm:rounded-lg">
          <DocumentsForm
            documents={documents}
            isSaving={isSaving && !dataToSave.documents}
            onSave={(d) => onSave(d, 'documents')}
          />
        </div>
        <div className="bg-white p-6 shadow-md sm:rounded-lg">
          <ScheduleForm
            scheduledDays={scheduledDays}
            isSaving={isSaving && !dataToSave.scheduledDays}
            onSave={(d) => onSave(d, 'scheduledDays')}
          />
        </div>
        <CommandButtons />
      </form>
    </div>
  );
  function CommandButtons() {
    return (
      <div className="flex justify-between space-x-4" data-testid="ws-form-buttons">
        <Button
          onClick={() => {
            setDataToSave({});
            goBack();
          }}
          data-testid="ws-previou-btn"
          preIcon={<ArrowLeftIcon className="size-6" />}
        >
          {t('common.previous')}
        </Button>
        <Button
          primary
          loading={isSaving}
          disabled={isSaving}
          onClick={() => {
            setIsSaving(true);
            setErrors({});
          }}
          data-testid="ws-finish-btn"
          postIcon={<ArrowRightIcon className="size-6" />}
        >
          {t('common.continue')}
        </Button>
      </div>
    );
  }
};

export default EnrollmentPage;
