export const INVITATION_TYPES = {
  parent: 'parent',
  staff: 'staff',
  enrollment: 'enrollment',
};

export const SERVICE_DIRECTION = {
  Previous: 'previous',
  Next: 'next',
};

export const DISPLAY_INTERVAL = {
  Month: 'month',
  Weekly: 'week',
  Biweekly: 'twoWeeks',
};
