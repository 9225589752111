import Intercom from '@intercom/messenger-js-sdk';
const INTERCOM_APP_ID = 'rmsozr7n';

export function identifyIntercom(authUser) {
  if (!authUser || !authUser.email) return;

  Intercom({
    app_id: INTERCOM_APP_ID,
    user_id: authUser.uid,
    name: authUser.displayName,
    email: authUser.email,
    phone: authUser.phoneNumber,
  });
}
