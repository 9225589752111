import { ContactStatus } from '@wonderschool/common-base-types';
import { t } from 'i18next';
import { showErrorToast, showSuccessToast } from '../Components/Shared/showToast';

export function showInvitationSuccessToast(contact) {
  const { displayName, email } = contact;

  const message = t('An invitation was sent to {{displayName}} at ({{email}})', { displayName, email });
  showSuccessToast(t('Invitation Sent'), message);
}

export function showInvitationErrorToast(contact, error) {
  const { displayName, email } = contact;
  const message = t('Failed to send an invitation to {{displayName}} at ({{email}})', { displayName, email });
  showErrorToast(t('Invitation Failed'), message, error);
}

export function canSendInvitation(contact) {
  return [ContactStatus.new, ContactStatus.invited].includes(contact?.status) && !!contact?.email;
}
