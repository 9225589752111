import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    Intercom: ((cmd: 'update' | 'boot', props?: { last_request_at?: number }) => void) | undefined;
  }
}

export const useIntercomUpdate = () => {
  const location = useLocation();

  useEffect(() => {
    // Only update if Intercom is available
    if (typeof window !== 'undefined' && window.Intercom) {
      window.Intercom('update', {
        last_request_at: Math.floor(Date.now() / 1000),
      });
    }
  }, [location]); // Re-run effect when location changes
};
