import { ContactStatus, ContactType, StudentContactRelationshipType } from '@wonderschool/common-base-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useOrganization } from '../hooks/useOrganizations';

const selectContactsList = createSelector([(state) => state.contacts], (contacts) => contacts?.list ?? []);

export const useContacts = () => {
  const contacts = useSelector(selectContactsList);
  const organization = useOrganization();

  const parentContacts = useMemo(() => {
    return contacts?.filter(
      (contact) =>
        contact.type === ContactType.FAMILY &&
        (contact.relationship === StudentContactRelationshipType.parent ||
          contact.relationship === StudentContactRelationshipType.guardian) &&
        contact.status !== ContactStatus.deleted
    );
  }, [contacts]);

  const familyContacts = useMemo(() => {
    return contacts?.filter((contact) => {
      return contact.type === ContactType.FAMILY && contact.status !== ContactStatus.deleted;
    });
  }, [contacts]);

  // This will return only staff contacts that are not deleted. Active staffs and disabled staffs will be returned.
  const staffContacts = useMemo(() => {
    return contacts?.filter(
      (contact) => contact.type === ContactType.STAFF && contact.status !== ContactStatus.deleted
    );
  }, [contacts]);

  // This will return all the staffs. Active staffs, disabled staffs, and deleted staffs will be returned.
  const allStaffContacts = useMemo(() => {
    return contacts?.filter((contact) => contact.type === ContactType.STAFF);
  }, [contacts]);

  const directorContact = useMemo(() => {
    return staffContacts?.find(
      (contact) => contact.uid === organization.createdBy && contact.status !== ContactStatus.deleted
    );
  }, [organization.createdBy, staffContacts]);

  return { contacts, parentContacts, familyContacts, staffContacts, directorContact, allStaffContacts };
};
