import { Checkbox, Input, PhoneInput } from '@wonderschool/common-base-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { fetchEmailUsage } from '../../api/firebase/users';
import { FormComponentProps } from '../../common';
import { canSendInvitation, useContacts } from '../../contacts';
import { phoneNumberFormat, phoneNumberParse } from '../../helpers/utils';
import { logError } from '../../rollbar';
import { DEFAULT_PARENT } from '../consts';
import { ParentType } from '../types';

type ParentEditFormProps = FormComponentProps<ParentType> & {
  parent?: ParentType;
  parents?: ParentType[];
  onChangeParent?: (parent: ParentType) => void;
  errors?: Record<string, string>;
};

function ParentEditForm({
  title,
  hasTitle = true,
  isSaving,
  onSave,
  parent: parentParam,
  parents = [],
  errors: externalErrors,
}: ParentEditFormProps) {
  const { t } = useTranslation();
  const { contacts } = useContacts();

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
    watch,
    register,
    setError,
  } = useForm<ParentType>({
    defaultValues: parentParam ?? { ...DEFAULT_PARENT },
  });

  const parent = watch();

  const canSendInvitationToParent = useCallback((parentLocal: ParentType): boolean => {
    return !parentLocal.contact || (parentLocal.contact && canSendInvitation(parentLocal.contact));
  }, []);

  const shouldShowSendInviteCheckbox = useMemo<boolean>(() => {
    return canSendInvitationToParent(parent);
  }, [parent, canSendInvitationToParent]);

  const isEmailInUse = useCallback(
    (email: string): boolean => {
      if (!email) return false;

      const contact = contacts.find((contact: any) => contact.email === email && !parent.contact?.id);
      if (contact) return true;

      const parentFound = parents.find(
        (p: ParentType) =>
          p.email === email && p.uuid !== parent.uuid && !parent.contact?.id && !parent.isInterestedFamily
      );
      return !!parentFound;
    },
    [contacts, parent, parents]
  );

  const onSubmit = useCallback(
    async (data: ParentType) => {
      try {
        const phone = data.phone ? phoneNumberFormat(data.phone) : '';

        if (data.email && !data.contact?.id) {
          const { hasRootUser, hasAuthUser, uid } = await fetchEmailUsage(data.email);

          // user associated with the email has to also have an organization attached
          // for "hasRootUser" to be true
          if (hasRootUser) {
            setError('email', { message: t('common.userAlreadyExistsError') });
            return;
          }

          onSave?.({ data: { ...data, phone, hasAuthUser, uid: uid ?? undefined } });
        }

        onSave?.({ data: { ...data, phone } });
      } catch (error: any) {
        logError('ParentEditForm: onSubmit: catch', error);
        onSave?.({ errors: { message: error.message } });
      }
    },
    [onSave, t, setError]
  );

  useEffect(() => {
    if (parentParam) {
      const phone = parentParam.phone ? phoneNumberParse(parentParam.phone) : '';
      reset({ ...parentParam, phone, shouldSendInvitation: canSendInvitationToParent(parentParam) });
    }
  }, [parentParam, canSendInvitationToParent, reset]);

  useEffect(() => {
    if (isSaving) {
      handleSubmit(onSubmit)();
    }
  }, [isSaving, handleSubmit, onSubmit]);

  const viewTitle = hasTitle ? t(title || 'students.newParentFormTitle') : '';

  const errors = { ...formErrors, ...externalErrors };

  return (
    <div className="parent-edit-form" data-testid="parent-edit-form">
      {!!viewTitle && (
        <h2 className="mb-4 text-2xl font-bold" data-testid="parent-edit-title">
          {viewTitle}
        </h2>
      )}
      <div className="-mx-2 flex flex-wrap">
        <div className="mb-4 w-full px-2 md:w-1/2">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: t('common.firstNameRequired') }}
            render={({ field }) => (
              <Input
                {...field}
                required
                type="text"
                placeholder={t('common.firstName')}
                label={t('common.firstName')}
                data-testid="parent-first-name"
                className="w-full rounded border p-2"
                error={errors.firstName?.message}
              />
            )}
          />
        </div>
        <div className="mb-4 w-full px-2 md:w-1/2">
          <Controller
            name="lastName"
            control={control}
            rules={{ required: t('common.lastNameRequired') }}
            render={({ field }) => (
              <Input
                {...field}
                required
                type="text"
                placeholder={t('common.lastName')}
                label={t('common.lastName')}
                data-testid="parent-last-name"
                className="w-full rounded border p-2"
                error={errors.lastName?.message}
              />
            )}
          />
        </div>
      </div>
      <div className="-mx-2 flex flex-wrap">
        <div className="mb-4 w-full px-2 md:w-1/2">
          <Controller
            name="email"
            control={control}
            rules={{
              required: watch('shouldSendInvitation') ? t('common.emailRequired') : false,
              validate: (value) => !isEmailInUse(value) || t('students.emailExistsInContacts'),
            }}
            render={({ field }) => (
              <Input
                {...field}
                required={watch('shouldSendInvitation')}
                type="email"
                placeholder={t('common.email')}
                label={t('common.email')}
                data-testid="parent-email"
                className="w-full rounded border p-2"
                error={errors.email?.message}
              />
            )}
          />
        </div>
        <div className="mb-4 w-full px-2 md:w-1/2">
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                {...field}
                type="tel"
                placeholder="(123) 456-7890"
                label={t('Mobile Phone')}
                data-testid="parent-phone"
                error={errors.phone?.message}
              />
            )}
          />
        </div>
      </div>
      {shouldShowSendInviteCheckbox && (
        <div className="mb-4">
          <Controller
            name="shouldSendInvitation"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...register('shouldSendInvitation')}
                name={field.name}
                label={t('students.shouldSendInviteLabel')}
                data-testid="parent-create-invite"
                checked={field.value}
              />
            )}
          />
          <p className="mt-1 text-sm text-gray-600">{t('students.shouldSendInviteNote')}</p>
        </div>
      )}
    </div>
  );
}

export default ParentEditForm;
