import { StudentType } from '@wonderschool/common-base-types';
import { saveCSV } from '../../common';
import { phoneNumberParse } from '../../helpers/utils';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { DefaultDateFormat } from '../../helpers/dates';

export async function generateParentsListingCSV(
  studentsListCSVReport: StudentType[],
  allRooms: any,
  studentBillingDetails: string | any[],
  locationName: string
) {
  const headers = PARENTS_COLUMNS.map((column) => column.header).join(',') + '\n';
  const date = moment(new Date()).format(DefaultDateFormat);
  const selectedLocation = locationName;
  const filename = `Contact_list_${selectedLocation}_${date}.csv`;
  const body = studentsListCSVReport
    .map((student: any) => {
      if (student.family && Object.values(student.family).length > 0) {
        return Object.values(student.family)
          .map((family: any) => {
            return [
              student?.displayName,
              student?.rooms ? allRooms.find((room: any) => room.id === student.rooms[0])?.name : '',
              `${family?.displayName} (${family.relationship})`,
              family?.email ? family?.email : '-',
              family.phone ? phoneNumberParse(family?.phone) : '-',
              family?.allowLoginWithPin === true ? 'Yes' : 'No',
              family?.email === student?.responsibleForBilling?.email ? 'Primary Payer' : '-',
              family?.email === student?.responsibleForBilling?.email && studentBillingDetails?.includes(student.id)
                ? 'AutoPay'
                : '',
              family?.stats?.appLastOpened ? 'Yes' : 'No',
              family?.status,
            ].join(',');
          })
          .join('\n');
      }
    })
    .filter((s: any) => !!s)
    .join('\n');
  const csv = headers + body;
  saveCSV(csv, filename);
}

const PARENTS_COLUMNS = [
  { header: 'Name', dataKey: 'displayName' },
  { header: 'Room', dataKey: 'room' },
  { header: 'Family & Contacts', dataKey: 'FamilyandContacts' },
  { header: 'Email', dataKey: 'Email' },
  { header: 'Phone number', dataKey: 'PhoneNumber' },
  { header: 'Pick up allowed', dataKey: 'Pickup' },
  { header: 'Payment', dataKey: 'Payment' },
  { header: 'Autopay', dataKey: 'Autopay' },
  { header: 'Mobile app', dataKey: 'App' },
  { header: 'Invite status', dataKey: 'Invite' },
];
