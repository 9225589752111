import { combineReducers } from 'redux';
// Import action.
import { USER_SIGNED_OUT } from '../actions/types';

// Import reducers
import { ContactsReducerState, contactsReducer as contacts } from '../../contacts/contactsRedux';
import {
  MarketplaceReducerState,
  marketplaceReducer as marketplace,
} from '../../integrations/marketplace/marketplaceRedux';
import { UserflowReducerState, userflowReducer as userflow } from '../../integrations/userflow/userflowRedux';
import { NetworksReducerState, networksReducer as networks } from '../../networks/networksRedux';
import { SegmentReducerState, segmentReducer as segment } from '../../segment/segmentRedux';
import { StudentsReducerState, studentsReducer as students } from '../../students/studentsRedux';
import { TimecardsReducerState, timecardsReducer as timecards } from '../../timecards';
import { TransactionsReducerState, transactionsReducer as transactions } from '../../transactions/transactionsRedux';
import devices, { DevicesReducerState } from './devices';
import invitations, { InvitationsReducerState } from './invitations';
import layout, { LayoutReducerState } from './layout';
import locations, { LocationsReducerState } from './locations';
import { OnboardingReducerState, onboardingReducer as onboarding } from './onboarding';
import organizations, { OrganizationsReducerState } from './organizations';
import permissions, { PermissionsReducerState } from './permissions';
import roles, { RolesReducerState } from './roles';
import rooms, { RoomsReducerState } from './rooms';
import staff, { StaffReducerState } from './staff';
import stats, { StatsReducerState } from './stats';
import user, { UserReducerState } from './user';

// Billing
import {
  ajaxCallsInProgress,
  charges,
  employeesList,
  invoiceEdit,
  invoices,
  paymentDetails,
  paymentEmployees,
} from './billing';
import { AjaxStatusReducerState } from './billing/ajaxStatusReducer';
import { ChargesReducerState } from './billing/chargesReducer';
import { EmployeesReducerState } from './billing/employeesReducer';
import { InvoiceEditReducerState } from './billing/invoiceEditReducer';
import { InvoicesReducerState } from './billing/invoices';
import { PaymentDetailsReducerState } from './billing/paymentDetailsReducer';
import { PaymentEmployeesReducerState } from './billing/paymentEmployeesReducer';

export interface RootReducerState {
  ajaxCallsInProgress: AjaxStatusReducerState;
  charges: ChargesReducerState;
  contacts: ContactsReducerState;
  devices: DevicesReducerState;
  employeesList: EmployeesReducerState;
  invitations: InvitationsReducerState;
  invoiceEdit: InvoiceEditReducerState;
  invoices: InvoicesReducerState;
  layout: LayoutReducerState;
  locations: LocationsReducerState;
  marketplace: MarketplaceReducerState;
  networks: NetworksReducerState;
  onboarding: OnboardingReducerState;
  organizations: OrganizationsReducerState;
  paymentDetails: PaymentDetailsReducerState | null;
  paymentEmployees: PaymentEmployeesReducerState | null;
  permissions: PermissionsReducerState;
  roles: RolesReducerState;
  rooms: RoomsReducerState;
  segment: SegmentReducerState;
  staff: StaffReducerState;
  stats: StatsReducerState;
  students: StudentsReducerState;
  timecards: TimecardsReducerState;
  transactions: TransactionsReducerState;
  user: UserReducerState;
  userflow: UserflowReducerState;
}

const combinedReducers = combineReducers({
  ajaxCallsInProgress,
  charges,
  contacts,
  devices,
  employeesList,
  invitations,
  invoiceEdit,
  invoices,
  layout,
  locations,
  marketplace,
  networks,
  onboarding,
  organizations,
  paymentDetails,
  paymentEmployees,
  permissions,
  roles,
  rooms,
  segment,
  staff,
  stats,
  students,
  timecards,
  transactions,
  user,
  userflow,
});

// Handy trick to clear store upon user sign out.
const rootReducer = (state, action) =>
  action.type === USER_SIGNED_OUT ? combinedReducers(undefined, action) : combinedReducers(state, action);

export default rootReducer;
