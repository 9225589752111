import { ContactStatus } from '@wonderschool/common-base-types';
import { Combobox } from '@wonderschool/common-base-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContacts } from '../../../contacts';
import { WsDropdownOptionType, WsDropdownProps } from '../widgets';

type DropdownOptionType = WsDropdownOptionType<string>;
type StaffDropdownProps = Omit<WsDropdownProps<string>, 'options'>;

export const StaffDropdown: React.FC<StaffDropdownProps> = ({
  label,
  name,
  onChange,
  value = '',
  required,
  error,
  'data-testid': dataTestId = 'staff-select',
}) => {
  const { t } = useTranslation();
  const { staffContacts: staff } = useContacts();

  const options: DropdownOptionType[] = useMemo(() => {
    // We're temporarily filtering unverified staff until we change the logic to get assigned timecards to include unverified staff
    const optionsLocal = staff
      ?.filter((staff) => staff.status === ContactStatus.verified)
      .map((staff: any) => ({
        id: staff.id,
        name: staff.displayName ?? `$staff.firstName $staff.lastName`,
      }));
    return optionsLocal ?? [];
  }, [staff]);

  return (
    <Combobox
      label={label ?? t('Staff')}
      placeholder={t('timecards.staffMemberSelectPlaceholder')}
      name={name ?? 'staff'}
      options={options}
      required={required}
      error={error}
      value={value}
      onChange={onChange}
      data-testid={dataTestId}
      immediate
    />
  );
};

export default StaffDropdown;
