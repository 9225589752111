import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import { isTest, shouldEmulateFirebase } from '../../config/env';
import { firebaseConfig } from './config';

// Only initialize if it isn't already initialized.
if (!firebase.apps.length) {
  // Temp Migration work
  const app = firebase.initializeApp(firebaseConfig);
  if (isTest() || shouldEmulateFirebase()) {
    /**
     * To use firebase emulator
     * start firebase emulator: firebase emulators:start
     * start react app: yarn start:with-emulator
     */
    // Auth emulator
    if (!isTest()) {
      // don't use auth emulator during tests
      firebase.auth(app).useEmulator('http://127.0.0.1:9099');
    }
    // Firestore emulator
    firebase.firestore(app).useEmulator('127.0.0.1', 8080);
    // Functions emulator
    firebase.functions(app).useEmulator('127.0.0.1', 5001);
    // Storage emulator
    firebase.storage(app).useEmulator('127.0.0.1', 9199);
  }
}

// Export firebase stuff.
export const { auth, firestore, storage, functions } = firebase;
export default firebase;
