import { Invitation, KinderSystems } from '@wonderschool/common-base-types';
import { RadioButtonGroupItem } from '@wonderschool/common-base-ui';
import { GenderEnum } from '../common';
import { EnrollmentStatusEnum } from '../enrollments/enums';
import { InterestedFamilyType } from '../integrations/marketplace/types';

export interface StudentContact {
  allowLoginWithPin: boolean;
  displayName: string;
  email?: string;
  firstName: string;
  lastName: string;
  invitationId?: string;
  // TODO: convert this to 'parent' or other possible values
  relationship: string;
  // TODO: convert this to 'invited', 'inviting' or other possible values
  status?: string;
  // TODO: convert this to 'family' or other possible values
  type: string;
  uid?: string;
}

export interface StudentFamilyMap {
  [key: string]: StudentContact;
}

export interface Student {
  automaticPayment?: any;
  createdAt: string;
  createdBy: {
    email: string;
    uid: string;
  };
  displayName?: string;
  family?: StudentFamilyMap;
  familyMembers?: string[];
  firstName: string;
  lastName: string;
  nickName?: string;
  allergies?: string;
  birthday?: string;
  schedule?: string;
  id: string;
  invitation?: Invitation;
  isAbsent: boolean;
  isPresent: boolean;
  isSleeping: boolean;
  location: string;
  organization: string;
  picture: string;
  preventInvoiceReason?: string | null;
  rooms: string[];
  tourDate?: string | null;
  uid: string;
  enrollmentDate: string | null;
  // deprecated in favor of enrollment map
  enrollmentStatus: boolean;
  // TODO: complete the rest of the new fields in this map
  enrollment: {
    status: EnrollmentStatusEnum;
  };
  unenrollmentDate?: string | null;
  unenrollmentInitiatedBy?: string | null;
  unenrollmentReason?: string;
  updatedAt: string;
  updatedBy: {
    email: string;
    name: string;
    uid: string;
  };
  kinderSystems?: KinderSystems;
}

// The following type is meant only for consumption by the UI components. It should never be persisted directly.
export type ParentType = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  contact?: any; // set to the selected contact if the parent is already a contact
  shouldSendInvitation?: boolean; // set to true to send an invition to the parent
  isInterestedFamily?: boolean;
  hasAuthUser?: boolean; // set to true if the parent has an auth user.
  uid?: string; // set to the auth user uid if hasAuthUser is true
  uuid?: string; // When creating a new parent, this is the uuid. Not persisted. Used to find duplicate emails
  marketplaceFamilyId?: number;
};
// The following type is meant only for consumption by the UI components. It should never be persisted directly.
export type StudentType = {
  firstName: string;
  lastName: string;
  gender?: RadioButtonGroupItem | GenderEnum;
  birthday?: string | null;
};
export enum StudentStatusEnum {
  ACTIVE = 'active', // Not to be confused with Enrolled. This is just the opposite of archived.
  ARCHIVED = 'archived',
}
export type EnrollmentInformationType = {
  room: string;
  startDate?: Date;
  automaticallyEnroll?: boolean;
};

export type StudentAndParentsType = {
  student: StudentType;
  parents: ParentType[];
  interestedFamily?: InterestedFamilyType;
};
export type StudentOrParentsType = StudentType | ParentType;

export interface StudentDocumentTableCast {
  documentId: string;
  tags: string[];
  addedBy: string;
  uploadedDate: string | null;
  type: string;
  path?: string;
  refId?: string;
}
