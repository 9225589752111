import { useTranslation } from 'react-i18next';

import { EditFormSection, InputData, Label, showToast, ToastTypeEnum } from '@wonderschool/common-base-ui';
import { UpdateStudentInput, useStudentUpdateMutation } from '@wonderschool/common-food-program-data-access';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { userHasPermission } from '../../../api/firebase/account';

// eslint-disable-next-line no-restricted-imports
import { Image } from 'semantic-ui-react';
import { showErrorToast } from '../../../Components/Shared/showToast';
import ProfilePictureUploader from '../../../Components/Upload/ProfilePictureUploader';
import { useOrganization } from '../../../hooks/useOrganizations';
import { studentUpdated } from '../../studentsRedux';
import { useDefaultStudentValues } from './hooks/useDefaultStudentValues';
import { StudentFormType, StudentPersonalInformationProps } from './studentForm.type';
import { transformStudentFormTypeForUpdateMutation } from './utils/transformStudentFormTypeForUpdateMutation';

export function StudentPersonalInformationProviderApi({ student }: StudentPersonalInformationProps) {
  const [editMode, setEditMode] = useState(false);
  const [updateStudentMutation] = useStudentUpdateMutation();

  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(student.avatarUrl || undefined);

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const canRoleEditStudentPersonal = userHasPermission('can_edit_student_personal');
  const currentOrganization = useOrganization();

  const { memoizedValues, translatedEthnicityOptions, translatedGenderOptions, translatedRaceOptions } =
    useDefaultStudentValues(student);

  function handleEditModeChanged(newEditModeValue: boolean) {
    setEditMode(newEditModeValue);
  }

  const inputs: InputData<StudentFormType>[] = [
    {
      id: 'firstName',
      label: t('common.firstName'),
      inputType: 'text' as const,
      required: true,
    },
    {
      id: 'middleName',
      label: t('common.middleName'),
      inputType: 'text' as const,
    },
    {
      id: 'lastName',
      label: t('common.lastName'),
      inputType: 'text' as const,
      required: true,
    },
    {
      id: 'nickname',
      label: t('Nickname'),
      inputType: 'text' as const,
    },
    {
      id: 'birthDate',
      label: t('Birthday'),
      inputType: 'date' as const,
    },
    {
      id: 'gender',
      label: t('Gender'),
      inputType: 'select' as const,
      defaultValue: translatedGenderOptions.find((option) => option.value === memoizedValues.gender?.value),
      options: translatedGenderOptions,
    },
    {
      id: 'race',
      label: t('Race'),
      inputType: 'select' as const,
      defaultValue: translatedRaceOptions.find((option) => option.value === memoizedValues.race?.value),
      options: translatedRaceOptions,
    },
    {
      id: 'ethnicity',
      label: t('Ethnicity'),
      inputType: 'select' as const,
      defaultValue: translatedEthnicityOptions.find((option) => option.value === memoizedValues.ethnicity?.value),
      options: translatedEthnicityOptions,
    },
    {
      id: 'allergies',
      label: t('Allergies'),
      inputType: 'text' as const,
    },
    {
      id: 'medications',
      label: t('Medications'),
      inputType: 'text' as const,
    },
    {
      id: 'doctorName',
      label: t('Doctor'),
      inputType: 'text' as const,
    },
    {
      id: 'doctorPhone',
      label: t('common.phoneNumber'),
      inputType: 'phone' as const,
      'data-testid': 'doctorPhone',
    },
    {
      id: 'notes',
      label: t(`Notes`),
      inputType: 'textarea' as const,
    },
    {
      id: 'photosAllowed',
      label: t('Photos Allowed'),
      inputType: 'checkbox',
    },
  ];

  async function onSubmit(data: StudentFormType) {
    const studentInput = transformStudentFormTypeForUpdateMutation(data, avatarUrl);

    const formData: UpdateStudentInput = {
      studentInput,
      studentIdentifier: {
        id: student.id,
        firebaseId: student.firebaseId,
      },
    };

    if (student.id && student.id.trim() !== '') {
      await updateStudentMutation({
        variables: { input: formData },
        onCompleted: () => {
          showToast(ToastTypeEnum.Success, 'Student updated successfully');
          dispatch(studentUpdated());
        },
        onError: (error) => {
          console.error('updateStudentMutation error', error);
          showErrorToast(t('Unable to save student data'));
        },
      });
    }
  }

  return (
    <>
      <EditFormSection
        header={t('Personal Info')}
        onSubmit={onSubmit}
        inputs={inputs}
        values={memoizedValues}
        onEditModeChanged={handleEditModeChanged}
        dataTestId="student-personal-info-editor"
        mode="all"
      />
      {/* note: Depending on "editMode" is a bit hacky thing, but we didn't have time to handle "photo" inputType in EditFormSection */}
      {editMode && canRoleEditStudentPersonal && (
        <div className="rounded-lg bg-purple-50">
          {userHasPermission('can_edit_student') && student.firebaseId && currentOrganization.id && (
            <Label name={t('Student Photo')} extraClasses="p-4">
              <ProfilePictureUploader
                title="Uploading Student Picture"
                onUploaded={(imageUrl: string) => {
                  setAvatarUrl(imageUrl);
                }}
                uploadPath={`organizations/${currentOrganization.id}/students/${student.firebaseId}`}
                data-testid="upload-photo"
              />
            </Label>
          )}
          {avatarUrl && <Image src={avatarUrl} size="medium" className="p-4" rounded data-testid="picture" />}
        </div>
      )}
    </>
  );
}
