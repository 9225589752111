import { User } from 'firebase/auth';
import React from 'react';
import { connect } from 'react-redux';

import { FileServiceProvider } from '@wonderschool/file-service-client';
import ResponsiveContainer from './Components/Layout/ResponsiveContainer';
import { auth } from './api/firebase/firebase';

import { startOrganizationContactsListener } from './contacts/contactsRedux';
import { startOrganizationLocationsListener } from './redux/actions/locationActions';
import { getOrganizationSetupState, startOrganizationListener } from './redux/actions/organizationActions';
import { startOrganizationPermissionsListener } from './redux/actions/permissionActions';
import { startOrganizationRolesListener } from './redux/actions/roleActions';
import { startOrganizationRoomsListener } from './redux/actions/roomActions';
import { startOrganizationStatsListener } from './redux/actions/statsActions';
import { startUserProfileListener, userSignedIn, userSignedOut } from './redux/actions/userActions';
import { startOrganizationStudentsListener } from './students/studentsRedux';
import { startOrganizationClockedInListener } from './timecards/timecardsRedux';

import { isLocal } from './config/env';
import { AppRouter } from './navigation';

import { IContextType } from '@wonderschool/common-base-sso/components/WSAuthProvider';
import WSA from './WSA';
import { identifyHotjar } from './hotjar';
import { identifyIntercom } from './intercom';
import { RootReducerState } from './redux/reducers/rootReducer';
import { trackNewSession, trackRollbarUser, trackUser, trackUserSignout } from './rollbar';

interface State {
  hasPrevUser: boolean;
  prevOrgId: string | undefined;
  prevClaims: any | undefined;
}

interface Props extends IContextType {
  currentOrganization: {
    id: string;
    setup: {
      firstLocationCreated: boolean;
      firstRoomCreated: boolean;
      firstStaffCreated: boolean;
      firstStudentCreated: boolean;
    };
    fetching: boolean;
    programType: string;
    setupComplete: boolean;
    stripeId: string;
  };
  organizationId: string;
  needsOrgOnboarding: boolean;
  canStartOrgListeners: boolean;
  // wsauth
  userId: string;
  // actions
  userSignedIn: (x: any, y: any) => any;
  userSignedOut: () => any;
  startUserProfileListener: (userId: string) => any;
  startOrganizationListener: (organizationId: string) => any;
  startOrganizationPermissionsListener: (organizationId: string) => any;
  startOrganizationRolesListener: (organizationId: string) => any;
  startOrganizationLocationsListener: (organizationId: string) => any;
  startOrganizationRoomsListener: (organizationId: string) => any;
  startOrganizationStatsListener: (organizationId: string) => any;
  startOrganizationStudentsListener: (organizationId: string, parentUserId?: string) => any;
  startOrganizationClockedInListener: (organizationId: string) => any;
  startOrganizationContactsListener: (organizationId: string) => any;
}

type UnsubscribeType = () => any;

class App extends React.Component<Props, State> {
  state: State = {
    hasPrevUser: false,
    prevOrgId: undefined,
    prevClaims: undefined,
  };

  componentWillUnmount = () => {
    this.unsubscribeOrganizationListeners();
    this.unsubscribeUserListeners();
  };

  componentDidUpdate = () => {
    this.checkForUserUpdate();
    this.checkForOrgUpdate();
  };

  unsubscribeUserProfileListener: UnsubscribeType | null = null;
  unsubscribeOrganizationListener: UnsubscribeType | null = null;
  unsubscribeOrganizationPermissionsListener: UnsubscribeType | null = null;
  unsubscribeOrganizationRoleListener: UnsubscribeType | null = null;
  unSubscribeOrganizationLocationsListener: UnsubscribeType | null = null;
  unSubscribeOrganizationRoomsListener: UnsubscribeType | null = null;
  unSubscribeOrganizationStudentsListener: UnsubscribeType | null = null;
  unSubscribeOrganizationStatsListener: UnsubscribeType | null = null;
  unSubscribeOrganizationClockedInListener: UnsubscribeType | null = null;
  unSubscribeOrganizationContactsListener: UnsubscribeType | null = null;

  checkForUserUpdate = () => {
    const { hasPrevUser, prevClaims } = this.state;
    const {
      isReady,
      isAuthenticated,
      authUser,
      userId,
      authSession,
      idToken,
      userStatus,
      userClaims,
      userSignedIn,
      userSignedOut,
      organizationId,
    }: Props = this.props;

    if (isReady && isAuthenticated) {
      if (JSON.stringify(prevClaims) !== JSON.stringify(userClaims)) {
        this.setState({ prevClaims: userClaims });
        userSignedIn(authUser, userClaims);
        trackRollbarUser(authUser, organizationId);
      }

      // Hotjar Identify API, call once the user is ready, is authenticated and has a userId
      if (userId) {
        identifyHotjar();
        identifyIntercom(authUser);
      }
    }

    if (!hasPrevUser && isReady && isAuthenticated) {
      this.setState({ hasPrevUser: true });

      if (isLocal()) {
        console.log('++++++++++++++++ Wonderschool Dev ******************');
        console.log('User Found: ', {
          authUser,
          idToken,
          userStatus,
          userSession: authSession,
          moxitAdmin: !!userClaims?.moxitAdmin,
          organizationAdmin: !!userClaims?.organizationAdmin,
          organizationDevice: !!userClaims?.organizationDevice,
          locationAdmin: !!userClaims?.locationAdmin,
          supportStaff: !!userClaims?.supportStaff,
          teacher: !!userClaims?.teacher,
          parent: !!userClaims?.parent,
          billingAccount: !!userClaims?.billingAccount,
        });
        console.log('++++++++++++++++ Wonderschool Dev ******************');
      }

      // this will check if there is an org setup for the user already
      // and determines `needsOrgOnboarding`
      this.startProfileListener(userId);
    } else if (hasPrevUser && isReady && !isAuthenticated) {
      // signed out detected
      if (isLocal()) {
        console.log('++++++++++++++++ Wonderschool Dev ******************');
        console.log('🛑 User Disconnected 🛑');
        console.log('++++++++++++++++ Wonderschool Dev ******************');
      }

      this.unsubscribeUserListeners();
      trackRollbarUser(null, null);
      userSignedOut();
      this.setState({ hasPrevUser: false });
    }
  };

  checkForOrgUpdate = () => {
    const { prevOrgId } = this.state;
    const hasPrevOrg = !!prevOrgId;
    const { isAuthenticated, organizationId, needsOrgOnboarding, canStartOrgListeners } = this.props;

    if (isAuthenticated && !needsOrgOnboarding) {
      // user with good org
      const needsFirstOrg = !hasPrevOrg && !!organizationId;
      const hasDifferentOrg = hasPrevOrg && prevOrgId !== organizationId;
      const needsOrgListeners = canStartOrgListeners && (needsFirstOrg || hasDifferentOrg);

      if (needsOrgListeners) {
        this.props.forceUserRefresh();
        this.setState({ prevOrgId: organizationId });
        // Unsubscribe active listeners if any
        this.unsubscribeOrganizationListeners();
        // start new ones
        this.startOrganizationListener(organizationId);
        this.startOrganizationPermissionsListener(organizationId);
        this.startOrganizationRolesListener(organizationId);
        this.startOrganizationLocationsListener(organizationId);
        this.startOrganizationRoomsListener(organizationId);

        const parentUserId = this.props.userClaims?.parent ? this.props.userId : undefined;
        this.startOrganizationStudentsListener(organizationId, parentUserId);
        this.startOrganizationStatsListener(organizationId);
        this.startOrganizationClockedInListener(organizationId);
        this.startOrganizationContactsListener(organizationId);
      }
    } else if (hasPrevOrg && (!isAuthenticated || !organizationId)) {
      // signed out
      this.unsubscribeOrganizationListeners();
      this.setState({ prevOrgId: undefined });
    }
  };

  startProfileListener = (uid: string) => {
    this.unsubscribeUserProfileListener = this.props.startUserProfileListener(uid);
  };
  startOrganizationListener = (id: string) => {
    this.unsubscribeOrganizationListener = this.props.startOrganizationListener(id);
  };
  startOrganizationPermissionsListener = (id: string) => {
    this.unsubscribeOrganizationPermissionsListener = this.props.startOrganizationPermissionsListener(id);
  };
  startOrganizationRolesListener = (id: string) => {
    this.unsubscribeOrganizationRoleListener = this.props.startOrganizationRolesListener(id);
  };
  startOrganizationLocationsListener = (id: string) => {
    this.unSubscribeOrganizationLocationsListener = this.props.startOrganizationLocationsListener(id);
  };
  startOrganizationRoomsListener = (id: string) => {
    this.unSubscribeOrganizationRoomsListener = this.props.startOrganizationRoomsListener(id);
  };
  startOrganizationStudentsListener = (orgId: string, parentUserId?: string) => {
    this.unSubscribeOrganizationStudentsListener = this.props.startOrganizationStudentsListener(orgId, parentUserId);
  };
  startOrganizationStatsListener = (id: string) => {
    this.unSubscribeOrganizationStatsListener = this.props.startOrganizationStatsListener(id);
  };
  startOrganizationClockedInListener = (id: string) => {
    this.unSubscribeOrganizationClockedInListener = this.props.startOrganizationClockedInListener(id);
  };

  startOrganizationContactsListener = (id: string) => {
    this.unSubscribeOrganizationContactsListener = this.props.startOrganizationContactsListener(id);
  };
  // User listeners
  unsubscribeUserListeners = () => {
    if (this.unsubscribeUserProfileListener) this.unsubscribeUserProfileListener();
  };

  // Organization Listeners
  unsubscribeOrganizationListeners = () => {
    if (this.unsubscribeOrganizationListener) this.unsubscribeOrganizationListener();
    if (this.unsubscribeOrganizationPermissionsListener) this.unsubscribeOrganizationPermissionsListener();
    if (this.unsubscribeOrganizationRoleListener) this.unsubscribeOrganizationRoleListener();
    if (this.unSubscribeOrganizationLocationsListener) this.unSubscribeOrganizationLocationsListener();
    if (this.unSubscribeOrganizationRoomsListener) this.unSubscribeOrganizationRoomsListener();
    if (this.unSubscribeOrganizationStudentsListener) this.unSubscribeOrganizationStudentsListener();
    if (this.unSubscribeOrganizationStatsListener) this.unSubscribeOrganizationStatsListener();
    if (this.unSubscribeOrganizationClockedInListener) this.unSubscribeOrganizationClockedInListener();
    if (this.unSubscribeOrganizationContactsListener) this.unSubscribeOrganizationContactsListener();
  };

  render() {
    // This is temporary. I wanted to move the FileServiceProvider and the
    // ResponsiveContainer out of the AppRouter and up to the App level, but behind a feature flag
    // Should this actually go up one level into into Account.tsx?
    return (
      <FileServiceProvider
        configuration={{
          auth: auth().currentUser as User,
          fileServiceURL: process.env.REACT_APP_FILE_SERVICE_HOST ?? '',
          organizationId: this.props.organizationId,
        }}
      >
        <ResponsiveContainer>
          <AppRouter />
        </ResponsiveContainer>
      </FileServiceProvider>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => {
  const currentOrganization = state.organizations.currentOrganization;
  const { organizationId, needsOrgOnboarding, canStartOrgListeners } = getOrganizationSetupState(state.organizations);
  return {
    currentOrganization,
    organizationId,
    needsOrgOnboarding,
    canStartOrgListeners,
  };
};

const mapAuthContextToProps = ({
  isReady,
  isAuthenticated,
  needsVerification,
  authUser,
  authSession,
  userClaims,
  idToken,
  userStatus,
  forceUserRefresh,
}: IContextType) => {
  return {
    isReady,
    isAuthenticated,
    needsVerification,
    authUser,
    userId: authUser?.uid,
    authSession,
    userClaims,
    idToken,
    userStatus,
    forceUserRefresh,
  };
};

export default WSA.Components.withWSAuth(
  WSA.Components.mapAuthContextToProps(
    mapAuthContextToProps,
    connect<any, any, any, RootReducerState>(mapStateToProps, {
      userSignedIn,
      userSignedOut,
      startUserProfileListener,
      startOrganizationListener,
      startOrganizationPermissionsListener,
      startOrganizationRolesListener,
      startOrganizationLocationsListener,
      startOrganizationRoomsListener,
      startOrganizationStudentsListener,
      startOrganizationStatsListener,
      startOrganizationClockedInListener,
      startOrganizationContactsListener,
    })(App)
  ),
  {
    onSignIn: trackUser,
    onSignOut: trackUserSignout,
    onNewSession: trackNewSession,
  }
);
