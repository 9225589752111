import { ParentHandbookConfirmationModal, ParentHandbookPage } from '@wonderschool/ccms-ui';
import { FileCategory } from '@wonderschool/common-base-types';
import { Dialog } from '@wonderschool/common-base-ui';
import { useUploadFile } from '@wonderschool/file-service-client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useOrganization } from '../hooks/useOrganizations';
import { useUser } from '../hooks/useUser';
import { RouteNameEnum, useRoutes } from '../navigation';
import { logError } from '../rollbar';

const ParentHandbook = () => {
  const { gotoRouteByName } = useRoutes();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const user = useUser();
  const accountHolderName = user?.firstName + ' ' + user?.lastName;
  const { uploadFile, reset: resetUploadHook } = useUploadFile();
  const { id: currentOrganizationId } = useOrganization();

  const handleRouteToDocuments = () => {
    gotoRouteByName(RouteNameEnum.DOCUMENTS);
  };

  const handleFinish = async (pdfBlob: Blob) => {
    try {
      await uploadFile({
        file: new File([pdfBlob], 'parent-handbook.pdf', { type: 'application/pdf' }),
        options: {
          fileAction: 'downloadable',
          fileCategory: FileCategory.PARENT_HANDBOOK,
          metadata: {
            uploadPath: `/organizations/${currentOrganizationId}`,
          },
        },
      });
      resetUploadHook();
      // close the modal
      setIsDialogOpen(false);
      setShowConfirmation(true);
    } catch (error) {
      logError('Error uploading parent handbook', {
        clientError: error,
      });
    }
  };

  return (
    <>
      <Dialog
        isOpen={isDialogOpen}
        onClose={handleRouteToDocuments}
        extraDialogWrapperClasses="fixed inset-0 z-50 flex items-center justify-center"
        fullScreenOnMobile={true}
        fullScreenOnDesktop={true}
      >
        <ParentHandbookPage
          account_holder_name={accountHolderName}
          useParams={useParams}
          routeToDocuments={handleRouteToDocuments}
          onFinish={handleFinish}
        />
      </Dialog>
      {showConfirmation && (
        <ParentHandbookConfirmationModal
          isOpen={showConfirmation}
          onClose={handleRouteToDocuments}
          onShare={handleRouteToDocuments}
        />
      )}
    </>
  );
};

export default ParentHandbook;
